function Footer() {
    return (
        <div className="w-full h-max bg-lightGray dark:bg-darkGray">
            <div className="mx-auto p-8 md:max-w-screen-sm lg:max-w-[870px]">
                <h1 className="text-3xl font-medium my-4">Contact me</h1>
                <div className="w-full flex flex-col md:flex-row md:items-center gap-8">
                    <div className="">
                        <p className="text-2xl box-content w-full font-mono">hi@manokh.com</p>
                    </div>

                </div>
                <p className="text-gray-500 pt-16 text-sm opacity-50">© Anton Manokhin, 2025. All rights reserved</p>
            </div>
        </div>)
}

export default Footer;
